<template>
    <div class="pb-10 h-100 ml-2">
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div >
                    <!-- <span class="pointer" @click="goToCompanyList">Company</span> -->
                    <!-- <span v-if="$route.path.startsWith('/bankers/')">Bankers</span>
                    <span v-else-if="$route.path.startsWith('/suppliers/')">Suppliers</span> -->
                    <span>Board Members</span>
                </div>
            </div>

            <div class=" d-flex align-items-center" style="position: relative;width: 55% !important;">
                <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                <input type="text" class="search-input-field" v-model="search_key" @input="loadInvestors" placeholder="Search By Group Name" />
            </div>
            <div class="d-flex flex-wrap align-items-center mr-2 ml-8">
                <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="btn btn-smm btn-danger ml-3" style="border-radius: 6px !important;" :disabled="investors_selected.length < 1" @click="showDeleteGroup">- Delete</button>
                <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'"  class="btn btn-smm text-white btn-success ml-3" style="border-radius: 6px !important;" @click="createNew">+
                    Add New Group
                </button>
            </div>
        </div>
        <div class="pr-2 pb-20">
            <div class="w-100">
                <div class="table-start pt-2 pb-10" style="min-height: 26em;">
                    <div v-if="!ajaxCallInProgress && member_list.length == 0" class="text-center font-weight-bold mt-10">
                        <div>
                            <img src="/static/images/illustrator-inveators.svg" style="width: 250px;">
                        </div>
                        <div class="mt-10 fw-600 fs-18"> No Bankers Yet!</div>
                    </div>
                    <table v-else class="admin-new-table w-100 mt-5" style="border-collapse: collapse; position: relative;" width="100%">
                        <thead>
                            <tr>
                                <th class="pl-10" v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" >
                                    <label v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" :class="{'v-hidden':member_list.length == 0}" class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                        <input type="checkbox" v-model="check_all" class="custom-control-input" @change="checkAll" name="select_all" />
                                        <span class="custom-control-indicator" style="color: #fff !important; font-size: 20px; top: -10px !important;"></span>
                                        <span class="form-check-description fw-600"></span>
                                    </label>
                                </th>
                                <th v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" ></th>
                                <th width="15%">Group Name</th>
                                <th></th>
                                <th></th>
                                <th width="18%">MEMBERS</th>
                                <th></th>
                                <th width="20%">MEMBERS NAME</th>
                                <th></th>
                                <th width="30%">PASSWORD</th>
                                <th></th>
                                <th width="15%">ENABLE ACCESS</th>
                                <th width="10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ajaxCallInProgress">
                                <td colspan="13">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </td>
                            </tr>
                            <!-- <tr v-if="!ajaxCallInProgress && member_list.length == 0" class="text-center font-weight-bold">
                                <td colspan="13">No Investors Yet!</td>
                            </tr> -->
                            <template v-else-if="!ajaxCallInProgress && member_list.length" v-for="(investors,index) in member_list" >
                                <tr :key="index+'kjhh'" style="border: 1px solid #ecf1f9; margin-bottom: 3px;">
                                    <td class="pl-10" v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" >
                                        <label class="custom-control custom-checkbox digi-checkbox text-center" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                            <input class="custom-control-input" type="checkbox" v-model="investors.checked" @change="selectInvestors($event, investors.id, index)"
                                            name="select_one" />
                                            <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                            <span class="form-check-description fw-600"></span>
                                        </label>
                                    </td>
                                    <td class="text-left" v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" >
                                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                            <button class="pointer btn-icon pt-3" style="padding:0px!important;" @click="editInvestors(investors.id)" >
                                                <img src="/static/images/edit-new.svg" height="15px;">
                                            </button>
                                        </el-tooltip>
                                    </td>
                                    <td>
                                        <div v-if="investors.investor_details" class="pointer" @click="showAllMembers(index)">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>{{investors.group_name}}</div>
                                                <div><i class="icon vertical-align-middle ml-3" :class="{'icon-chevron-down':investors.show, 'icon-chevron-right':!investors.show}" style="color: #3f3f3f;"></i></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>{{investors.investor_count}}</td>
                                    <td></td>
                                    <td>-</td>
                                    <td></td>
                                    <td>-</td>
                                    <td></td>
                                    <td>-
                                    </td>
                                    <td class="text-center"></td>
                                </tr>
                                <template v-for="(member, comp_index) in investors.investor_details">
                                    <tr class="background-color-company" :key="comp_index+'ll'" v-if="investors.show">
                                        <td v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" ></td>
                                        <td v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" ></td>
                                        <td>-</td>
                                        <td></td>
                                        <td></td>
                                        <td>-</td>
                                        <td></td>
                                        <td>{{member.full_name}}</td>
                                        <td></td>
                                        <td>
                                            <button style="border-radius: 6px !important;" @click="regeneratePassword(member.id)" class="btn btn-smm btn-new-secondarys text-white">Regenerate</button>
                                        </td>
                                        <td></td>
                                        <td class="text-center">
                                            <span class="pointer" @click="enableAccess(member)">
                                                <el-switch v-model="member.access"></el-switch>
                                            </span>
                                        </td>
                                        <td><i v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" @click="showDeleteComp(member.id)" class="icon icon-delete text-danger pointer fs-22 ml-0"></i></td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div v-if="!ajaxCallInProgress && member_list.length > 0" class="d-flex align-items-center font-weight-bold p-2 justify-content-end bg-white" >
                    <multiselect class="diginew-multiselect" :options="pageLimit" :close-on-select="true" v-model.number="limit" :max-height="210" open-direction="top" :show-labels="false" :searchable="false" :allow-empty="false" :block-keys="['Delete']" placeholder="" style="width: 4.2em !important;padding-right: 10px!important;">
                    </multiselect>
                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                    <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                    <div :class="skip == 0 ? 'not-allowed' : ''">
                        <button @click="firstPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                            <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                                <i class="icon-chevron-double-left"></i>
                            </el-tooltip>
                        </button>
                    </div>
                    <div :class="skip == 0 ? 'not-allowed' : ''">
                        <button @click="previousPage()" :disabled="skip == 0" :class="{'not-allowed' : skip == 0}" class="pagination-list mt-1 ml-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                                <i class="icon icon-chevron-left"></i>
                            </el-tooltip>
                        </button>
                    </div>
                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                    <div :class="skip_temp >= total || total <= 10 ? 'not-allowed' : ''">
                        <button @click="nextPage()" :disabled="skip_temp >= total || total <= 10" :class="{'not-allowed' : skip_temp >= total || total <= 10 }" class="pagination-list mt-1 mr-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                                <i class="icon icon-chevron-right"></i>
                            </el-tooltip>
                        </button>
                    </div>
                    <div :class="temp_total == total || total <= 10 ? 'not-allowed' : ''">
                        <button @click="lastPage()" :disabled="temp_total == total || total <= 10" :class="{'not-allowed' : temp_total == total || total <= 10}" class="mt-1 pointer pagination-list">
                            <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                                <i class="icon-chevron-double-right"></i>
                            </el-tooltip>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="display_filters" class="scm-filters fadeInDown" :class="{'fadeInUp':hide_filters == true}" style="background-color: #e6ecff !important;height: 250px !important;">
            <filter-data :showfilterData="showfilterData" @hideFilters="hideFilters"></filter-data>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <delete-investors-modal v-if="delete_investors" :group_type = "group_type" @hideDeleteInvestorsModal="hideDeleteInvestorsModal" @deleteInvestorsSuccess="deleteInvestorsSuccess" modal_name='delete_investors_modal' ></delete-investors-modal>
        <delete-group-investors :group_type = "group_type" :investors_selected="investors_selected" v-if="delete_groups" @hideDeleteGroupsModal="hideDeleteGroupsModal" @deleteGroupsSuccess="deleteGroupsSuccess" modal_name='delete_groups_modal' ></delete-group-investors>
    </div>
</template>
<script>
import {
		EventBus
	} from "../eventBus/nav-bar-event.js";
import { HalfCircleSpinner } from 'epic-spinners';
const FilterData = () => import('../Consultant/FilterData');
import { SweetModal } from 'sweet-modal-vue';
import consultant from "../mixins/consultant.js"
import DeleteInvestorsModal from './DeleteInvestorsModal.vue';
import DeleteGroupInvestors from './DeleteGroupInvestors.vue';
import investors from '../mixins/investors';
export default({
    data(){
        return{
            warning_msg: '',
            success_msg: '',
            ajaxCallInProgress: false,
            limit:10,
            pageLimit: [],
            showfilterData: {
                started_on:"",
                department_head:[],
                business_unit_id:[],
                department_name:[]
            },
            filterData: {
                started_on:"",
                department_head:[],
                business_unit_id:[],
                department_name:[]
            },
            display_filters: false,
            hide_filters: false,
            skip: 0,
            limit: 10,
            total: 0,
            skip_temp:0,
            temp_total:0,
            member_list: [],
            search_key: "",
            delete_investors: false,
            check_all: false,
            investors_selected: [],
            delete_groups: false,
            investors_delete_id: [],
            group_type: "board"
        }
    },
    mixins:[consultant, investors],
    components: {
        FilterData,
        SweetModal,
        HalfCircleSpinner,
        DeleteInvestorsModal,
        DeleteGroupInvestors
    },
    methods: {
        showAllMembers(index) {
            if(this.member_list[index].show == true) {
                this.member_list[index].show = false;
            } else {
                this.member_list[index].show = true;
            }
            this.$forceUpdate();
        },
        clearSearch(){
            this.search_key = ""
            this.loadInvestors();
        },
        async deleteMemberData() {
            let member_data_id = []
            member_data_id.push(this.investors_delete_id)
            try {
                let params = {
                    id: member_data_id
                }
                let response = await this.deleteConsultant(params)
                if(response.status_id == 1) {
                    this.success_msg = response.message
                    this.$refs.success_modal.open();
                    this.consultant_selected = [];
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.loadInvestors();
                    }, 2000);
                }
            }
            catch(err) {
                // this.ajax_call_in_progress = false;
                // console.log(err)
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        },
        hideDeleteInvestorsModal() {
            this.delete_investors = false;
            // this.loadInvestors();
        },
        async deleteGroupsSuccess() {
            this.deleteComp();
        },
        showDeleteGroup() {
            if(this.investors_selected.length){
                this.delete_groups = true;
                setTimeout(() => {
                    this.$modal.show('delete_groups_modal');
                }, 500);
            }
        },
        hideDeleteGroupsModal() {
            this.delete_groups = false;
            this.investors_selected = []
            // this.loadInvestors();
        },
        async deleteInvestorsSuccess() {
            this.deleteMemberData();
        },
        showDeleteComp(id) {
            this.investors_delete_id = id
            this.delete_investors = true;
            setTimeout(() => {
                this.$modal.show('delete_investors_modal');
            }, 500);
        },
        async deleteComp() {
            try {
                let params = {
                    group_ids: this.investors_selected
                }
                let response = await this.deleteInvestors(params)
                if(response.status_id == 1) {
                    this.success_msg = response.message
                    this.$refs.success_modal.open();
                    this.investors_selected = [];
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.loadInvestors();
                    }, 2000);
                }
            }
            catch(err) {
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        },
        selectInvestors(event, id) {
            if (event.target.checked) {
                this.investors_selected.push(id);
            } else {
                let index = this.investors_selected.indexOf(id);
                if (index > -1) {
                    this.investors_selected.splice(index, 1);
                }
            }
            if (this.investors_selected.length === this.member_list.length) {
                this.check_all = true;
            } else {
                this.check_all = false;
            }
        },
        checkAll(event) {
            if (event.target.checked === true) {
                this.member_list.forEach((investors, index) => {
                    this.member_list[index].checked = true;
                    this.investors_selected.push(investors.id);
                })
            } else {
                this.member_list.forEach((investors, index) => {
                    this.member_list[index].checked = false;
                })
                this.investors_selected = [];
            }
        },
        showFilters() {
            if (this.display_filters) {
                this.hide_filters = true
                setTimeout(() => {
                    this.display_filters = false
                    this.hide_filters = false
                }, 500);
            } else {
                this.display_filters = true
            }
        },
        hideFilters() {
            this.hide_filters = true
            setTimeout(() => {
                this.display_filters = false
                this.hide_filters = false
            }, 500);
        },
        createNew(){
            this.$router.push('/board/createboard/cmpid/'+this.$route.params.company_id);
            // if(this.$route.path.startsWith('/bankers/')){
            //     this.$router.push('/bankers/createbankers/cmpid/'+this.$route.params.company_id);
            // }
            // else if(this.$route.path.startsWith('/suppliers/')){
            //     this.$router.push('/suppliers/createsuppliers/cmpid/'+this.$route.params.company_id);
            // }else{
            //     this.$router.push('/investors/createinvestors/cmpid/'+this.$route.params.company_id);
            // }
        },
        editInvestors(id) {
            this.$router.push(`/board/editboard/cmpid/${this.$route.params.company_id}/${id}`);
            // if(this.$route.path.startsWith('/bankers/')){
            //     this.$router.push(`/bankers/editbankers/cmpid/${this.$route.params.company_id}/${id}`);
            // }else if(this.$route.path.startsWith('/suppliers/')){
            //     this.$router.push(`/suppliers/editsuppliers/cmpid/${this.$route.params.company_id}/${id}`);
            // }else{
            //     this.$router.push(`/investors/editinvestors/cmpid/${this.$route.params.company_id}/${id}`);
            // }
        },
        previousPage() {
            this.temp_total = 0
            this.skip_temp = 0
            if (this.skip == 0) {
                return;
            }
            this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            this.loadInvestors();
        },
        nextPage() {
            this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp;
            this.loadInvestors();
        },
        firstPage(){
            this.temp_total = 0
            this.skip = 0
            this.limit = this.limit
            this.loadInvestors();
        },
        lastPage() {
            this.skip_temp = 0
            this.skip = 0
            this.temp_total = this.total
            let no_of_pages = Math.ceil(this.total/this.limit)
            this.skip_temp = (no_of_pages * this.limit) - this.limit
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp
            this.loadInvestors();
        },
        async loadInvestors(){
            this.ajaxCallInProgress = true
            this.member_list = []
            this.check_all = false;
            let params = {
                skip: this.skip,
                limit: this.limit,
                search_key: this.search_key,
                company_id: this.$route.params.company_id,
                group_type: this.group_type
            }
            let response = await this.getInvestorstListsData(params)
            this.member_list = response.response
            this.member_list.forEach((org, index) => {
                this.member_list[index].show = false;
                this.member_list[index].checked = false;
            })
            this.member_list.forEach(y => {
                y.investor_details.forEach(data => {
                    data['checked'] = false
                    if(data.status == 'active'){
                        data['access'] = true
                    }
                    else{
                        data['access'] = false
                    }
                })
            })
            this.total = response.total_count;
            this.ajaxCallInProgress = false
        },
        async regeneratePassword(id){
            let params = {
                consultant_id: id
            }
            let response = await this.regenerateConsultant(params)
            this.success_msg = response.message
            this.$refs.success_modal.open();
            setTimeout(() => {
                this.$refs.success_modal.close();
                this.loadInvestors();
            }, 2000);
        },
        async enableAccess(member){
            let params = {
                status: member.status == "active" ? "terminate" : "active"
            }
            let response = await this.editConstultant(params,member.id)
            this.success_msg = response.message
            this.$refs.success_modal.open();
            setTimeout(() => {
                this.$refs.success_modal.close();
                this.loadInvestors();
            }, 2000);
        }
    },
    computed: {
        getSkipCount() {
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
    },
    mounted(){
        // for (let i = 1; i <= 50; i++) {
        //     i += 9
        //     this.pageLimit.push(i);
        // }
        this.loadInvestors()
    },
    created(){
        for (let i = 10; i <= 50; i+=10) {
            this.pageLimit.push(i);
        }
    },
    watch: {
        limit: function(){
            this.loadInvestors()
        },
    }
})
</script>
<style scoped>
.link-name{
    color:#00448b
}
.decoration-none{
    text-decoration: none;
}
.table-view-start {
    background-color: #fff;
}
.search-input-field {
    padding: 8px 9.4px 8px 30px;
    border-radius: 18px !important;
    background-color: #fff;
    width: 100%;
}
.table-start {
    background-color: #fff;
    padding-top: 5px;
    border-radius: 8px;
    overflow-x: auto !important;
}
.scm-filters{
    width: 300px !important;
    height: 300px !important;
    right:100px;
}
.btn-new-green {
    color: #fff;
    background-color: #128807;
    border-radius: 5px;
}
.pointer-events-none {
    pointer-events: none;
}
.not-allowed{
    cursor: no-drop !important;
}
.background-color-company{
    background-color: #ecf1f9;
    border-bottom: 3px solid #fff;
}
.btn-new-secondarys {
    color: #fff;
    border-radius: 5px;
    padding-left: 20px !important;
    padding-right: 18px !important;
    text-transform: none;
    font-weight: 500;
    line-height: 30px;
    box-shadow: none;
    background-color: #00448b;
    border-color: 2px solid #00448b;
    box-shadow: none !important;
    font-weight: 500;
}
</style>